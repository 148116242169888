import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { htmlSafe } from '@ember/template';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import { getObservableProductRoute, extractContent } from 'tio-employee/services/observability';
import type { ObservableSource } from 'tio-employee/services/observability';
import type ObservabilityService from 'tio-employee/services/observability';
import type SessionContextService from 'tio-employee/services/session-context';
import AccountSummary from 'tio-employee/components/observability/account-summary';
import { Header, Section, VStack } from 'tio-ui/components/layout';
interface ObservabilityConfirmSignature {
    Args: {
        model: {
            source: ObservableSource;
            observableDocument: ObservableDocumentModel;
        };
    };
}
let ObservabilityConfirm = class ObservabilityConfirm extends Component<ObservabilityConfirmSignature> {
    @service
    intl: IntlService;
    @service
    router: RouterService;
    @service
    observability: ObservabilityService;
    @service
    sessionContext: SessionContextService;
    get employeeEmail() {
        return this.sessionContext.user?.primaryEmail?.email;
    }
    get subtitleSections() {
        const result1 = this.intl.t('observability.confirmation.subtitle', {
            email: this.employeeEmail
        });
        return result1.split('\n');
    }
    // TODO: generalize this - should take url, link text, and class (default to tio-anchor)
    // James 2024-11-20
    get amendLink(): string {
        const linkText1 = this.intl.t('click_here').toLowerCase();
        const url1 = this.router.urlFor('authenticated.observability.dispute');
        return htmlSafe(`<a href="${url1}" class="tio-anchor">${linkText1}</a>`) as unknown as string;
    }
    get loanSummary() {
        const { observableDocument: observableDocument1 } = this.args.model;
        return extractContent(observableDocument1);
    }
    get documentPartiallyApplied() {
        return (this.args.model.observableDocument.reflectionState === 'ReflectionState.PARTIALLY_APPLIED');
    }
    static{
        template(`
    <VStack>
      <Header>{{t "observability.confirmation.header"}}</Header>
      <Section>
        <:header>{{t "observability.confirmation.title"}}</:header>
        <:body>
          {{#each this.subtitleSections as |section|}}
            <p class="my-4">{{section}}</p>
          {{/each}}
          {{#each this.loanSummary as |account|}}
            <AccountSummary
              @servicerName={{account.servicer}}
              @balance={{account.balance}}
              @accountNumber={{account.accountNumber}}
              @loans={{account.loans}}
              class="sm:w-1/2 my-8"
              as |summary|
            >
              {{#if this.documentPartiallyApplied}}
                <summary.footer>
                  {{t "observability.document.summary.partially_applied"}}
                </summary.footer>
              {{else}}
                <summary.footer>
                  {{t "observability.confirmation.amend" clickHere=this.amendLink htmlSafe=true}}
                </summary.footer>
              {{/if}}
            </AccountSummary>
          {{/each}}
          <LinkTo
            @route={{getObservableProductRoute @model.source}}
            class="tio-btn tio-btn-primary"
          >
            {{t "continue"}}
          </LinkTo>
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(ObservabilityConfirm);
