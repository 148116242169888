import { template as template1 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { all, dropTask } from 'ember-concurrency';
import { Button } from 'tio-ui/components/buttons';
import { currencyStringToNumber } from 'tio-common/utils/format';
import { Divider } from 'tio-ui/components/utilities';
import { fn } from '@ember/helper';
import { HStack, Section, VStack } from 'tio-ui/components/layout';
import { Input, Select } from 'tio-ui/components/forms';
import { Modal, Footer } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { or, eq } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { setSemesterCodeString } from 'tio-common/utils/format';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';
import { getAllValuesForTasField, getSingleValueForTasField, setSingleValueForTasField, getConfigForTasField, type FieldName, type FieldValue, type TemplateFieldName } from 'tio-common/utils/tuition-assistance/fields';
import AcceptConditions from 'tio-employee/components/tuition-assistance/forms/accept-conditions';
import ActionableList from 'tio-ui/components/actionable-list';
import AddEducationDrawer from 'tio-employee/components/tas/add-education-drawer';
import AddScholarshipsDrawer from 'tio-employee/components/tas/add-scholarships-drawer';
import CheckCircle from 'ember-static-heroicons/components/outline-24/check-circle';
import CheckCircleSolid from 'ember-static-heroicons/components/solid-24/check-circle';
import ClipboardDocumentList from 'ember-static-heroicons/components/outline-24/clipboard-document-list';
import Component from '@glimmer/component';
import divide from 'ember-math-helpers/helpers/div';
import inputmask from 'tio-common/modifiers/inputmask';
import join from 'ember-composable-helpers/helpers/join';
import LimitsSummary from 'tio-common/components/tuition-assistance/limits-summary';
import MissingRequirements from 'tio-employee/components/tas/missing-requirements';
import PencilSquare from 'ember-static-heroicons/components/outline-24/pencil-square';
import Plus from 'ember-static-heroicons/components/solid-24/plus';
import ProgramFormField from 'tio-common/components/tuition-assistance/program-form-field';
import ReimbursementCalculationIfApplicable from 'tio-common/components/tuition-assistance/reimbursement-calculation-if-applicable';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import SchoolSelect from 'tio-common/components/tuition-assistance/program-details/school-select';
import TasPageHeader from 'tio-employee/components/tas/page-header';
import TioAlert from 'tio-common/components/tio/alert';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import TuitionAssistanceFormsCustomFieldsForProvince from 'tio-common/components/tuition-assistance/forms/custom-fields-for-province';
import type { ReceivedScholarship } from 'tio-common/types/tuition-assistance';
import type { TasFieldsOrCustomFields } from 'tio-common/types/tuition-assistance';
import type { TASProgramInstanceModelFieldsSignature } from 'tio-common/models/tas-program-instance';
import type { TASProgramTemplateModelFieldsSignature } from 'tio-common/models/tas-program-template';
import type ConditionModel from 'tio-common/models/condition';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TasCourseModel from 'tio-common/models/tas-course';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplateInstitutionModel from 'tio-common/models/tas-program-template-institution';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
import type TasUnifiedInstitutionModel from 'tio-common/models/tas-unified-institution';
import UploadAttachments from 'tio-common/components/tuition-assistance/forms/upload-attachments';
import XCircle from 'ember-static-heroicons/components/outline-24/x-circle';
type ProgramInstanceFieldName = keyof TASProgramTemplateModelFieldsSignature;
export type FormField = {
    name: ProgramInstanceFieldName;
    mask: string | null;
    type: string | null;
    rules: {
        type: string;
        typeError?: string;
    };
    disabled: boolean;
};
export interface NewTypeTwoOrThreeSignature {
    Args: {
        model: {
            application: TasApplicationModel;
            programInstance: TASProgramInstanceModel;
            selectedSchool?: TasUnifiedInstitutionModel;
        };
    };
}
interface Scholarship extends ReceivedScholarship {
    id?: string;
}
export default class NewTypeTwoOrThreeComponent extends Component<NewTypeTwoOrThreeSignature> {
    programTemplate: TasProgramTemplateModel;
    constructor(owner1: unknown, args1: NewTypeTwoOrThreeSignature['Args']){
        super(owner1, args1);
        const { application: application1 } = args1.model;
        this.programTemplate = application1.tasProgramInstance.tasProgramTemplate;
        this.scholarshipToDelete = null;
        this.scholarshipToEdit = null;
        this.eligibility = this.updateEligibility() as unknown as boolean;
        this.setSelectedSchoolIfExists();
    }
    @tracked
    acceptedConditions: ConditionModel[] = [];
    @tracked
    course: TasCourseModel | null = null;
    @tracked
    eligibility: boolean;
    @tracked
    hasSubmitted = false;
    @tracked
    isDeleteApplicationOpen = false;
    @tracked
    isDeleteCourseOpen = false;
    @tracked
    isDeleteScholarshipOpen = false;
    @tracked
    isEditScholarshipOpen = false;
    @tracked
    isEducationOpen = false;
    @tracked
    isOpen = false;
    @tracked
    isScholarshipOpen = false;
    @tracked
    scholarshipEditError = '';
    @tracked
    scholarshipToDelete: Scholarship | null;
    @tracked
    scholarshipToEdit: Scholarship | null;
    @tracked
    selectedSchool?: TasUnifiedInstitutionModel;
    @tracked
    submitError = null;
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @service
    router: RouterService;
    calculateEligibility(periodOfEligibility1: [string, string][]) {
        if (periodOfEligibility1.length === 0) {
            return false;
        }
        if (periodOfEligibility1.length === 1) {
            const programEligibility1 = periodOfEligibility1.flat();
            return (this.startOrEndDate >= programEligibility1[0]! && this.startOrEndDate <= programEligibility1[1]!);
        }
        for (const eligibility1 of periodOfEligibility1){
            if (this.startOrEndDate >= eligibility1[0] && this.startOrEndDate <= eligibility1[1]) {
                return true;
            }
        }
    }
    async updateEligibility(): Promise<boolean> {
        const { id: participantId1 } = this.application.tasProgramInstance.employee.tasParticipant;
        const { code: code1 } = this.instance.tasProgramTemplate;
        try {
            const [dynamicEligibility1, programEligibility1] = await Promise.all([
                this.store.adapterFor('tas-participant').dynamicEligibility(participantId1, code1),
                this.store.adapterFor('tas-participant').periodOfEligibility(participantId1, code1)
            ]);
            const eligibilityResult1 = dynamicEligibility1 && this.calculateEligibility(programEligibility1);
            this.eligibility = eligibilityResult1;
            return eligibilityResult1;
        } catch (error1) {
            console.error('Error fetching eligibility:', error1);
            this.eligibility = false;
            return false;
        }
    }
    setSelectedSchoolIfExists() {
        if (this.args.model.selectedSchool) {
            this.selectedSchool = this.args.model.selectedSchool;
        }
    }
    get programFormFieldsForTypeTwoAndThree(): FormField[] {
        const numberTypeError1 = 'Value must be a number';
        return [
            {
                name: 'APPLICATION_NAME',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'APPLICATION_ID',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: true
            },
            {
                name: 'NAME',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: true
            },
            {
                name: 'EMPLOYEE_ID',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: true
            },
            {
                name: 'FT_PT_OTHER',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: true
            },
            {
                name: 'EMPLOYEE_DOB',
                mask: null,
                type: 'date',
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'EMPLOYEE_EMAIL',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'SCHOOL_INSTITUTION_NAME',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'PROGRAM_SPECIALIZATION',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'PROGRAM_MAJOR',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'ESTIMATED_COST',
                mask: 'currency',
                type: 'number',
                rules: {
                    type: 'number',
                    typeError: numberTypeError1
                },
                disabled: false
            },
            {
                name: 'ENROLLMENT_OBJECTIVE',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'STUDENT_ID',
                mask: null,
                type: null,
                rules: {
                    type: 'string'
                },
                disabled: false
            },
            {
                name: 'ESTIMATED_PROGRAM_BEGIN',
                mask: null,
                type: 'date',
                rules: {
                    type: 'date'
                },
                disabled: false
            },
            {
                name: 'ESTIMATED_PROGRAM_COMPLETION',
                mask: null,
                type: 'date',
                rules: {
                    type: 'date'
                },
                disabled: false
            }
        ];
    }
    get application() {
        return this.args.model.application;
    }
    get instance() {
        return this.args.model.programInstance;
    }
    get applicationFields(): TasApplicationModel['fields'] {
        return this.application.fields;
    }
    get applicationName() {
        return getSingleValueForTasField('APPLICATION_NAME', this.applicationFields);
    }
    get educationSectionTitle() {
        return this.application.expenseType || 'Education';
    }
    get typeClassification() {
        return this.programTemplate.typeClassification || '';
    }
    get conditionsForSubmit() {
        const conditionsMap1 = {
            'TAS.ProgramType.2': this.programTemplate.typeTwoApplicationConditions,
            'TAS.ProgramType.3': this.programTemplate.applicationApproveCoursesConditions
        };
        return conditionsMap1[this.typeClassification as keyof typeof conditionsMap] || [];
    }
    get hasAgreedAllConditions() {
        if (!this.conditionsForSubmit.length) {
            return true;
        }
        return this.conditionsForSubmit.length === this.acceptedConditions.length;
    }
    saveAgreementsForConditions = dropTask(async ()=>{
        const user1 = this.sessionContext.user;
        const agreements1 = this.acceptedConditions.map((condition1)=>{
            return this.store.createRecord('agreement', {
                user: user1,
                condition: condition1
            });
        });
        const promises1 = agreements1.map((agreement1)=>{
            return agreement1.save();
        });
        const saved1 = await all(promises1);
        return saved1;
    });
    get requireAttachmentUploads() {
        if (this.typeClassification === 'TAS.ProgramType.2') {
            return (this.programTemplate.requireAttachmentsForCourseApproval || this.programTemplate.requireAttachmentsForCourseCompletion);
        }
        return this.programTemplate.requireAttachmentsForCourseApproval;
    }
    get attachmentClassificationString() {
        return this.typeClassification === 'TAS.ProgramType.2' ? 'COMPLETION_DOCUMENTS' : 'COURSE_APPROVAL_DOCUMENTS';
    }
    get attachmentsListItems() {
        const { programApproval: programApproval1, coursePreApproval: coursePreApproval1, courseCompletion: courseCompletion1 } = this.programTemplate.attachmentListItems;
        const itemsToInclude1 = this.typeClassification === 'TAS.ProgramType.2' ? [
            programApproval1,
            coursePreApproval1,
            courseCompletion1
        ] : [
            programApproval1,
            coursePreApproval1
        ];
        return itemsToInclude1.flat();
    }
    get attachmentsListDescription() {
        const { programApproval: programApproval1, coursePreApproval: coursePreApproval1, courseCompletion: courseCompletion1 } = this.programTemplate.attachmentInstructions;
        const itemsToInclude1 = this.typeClassification === 'TAS.ProgramType.2' ? [
            programApproval1,
            coursePreApproval1,
            courseCompletion1
        ] : [
            programApproval1,
            coursePreApproval1
        ];
        return itemsToInclude1.join(' ');
    }
    get instanceCustomFields() {
        return this.instance.customFields;
    }
    get applicationCustomFields() {
        return this.application.customFields;
    }
    get lockStatus() {
        const lock1 = getSingleValueForTasField('LOCK_EMPLOYEE_STATUS_BASED_ON', this.programTemplate.fields) as string;
        const lockType1: Record<string, string> = {
            COURSE_START_DATE: 'start',
            COURSE_END_DATE: 'end'
        };
        return lockType1[lock1] || 'start';
    }
    get expenseTypeField() {
        let expenseType1 = getSingleValueForTasField('EXPENSE_TYPE', this.programTemplate.fields) as string;
        return expenseType1 || 'Education';
    }
    get scholarships() {
        let savedScholarships1 = getAllValuesForTasField('SCHOLARSHIPS_RECEIVED', this.application.fields);
        return savedScholarships1.map((scholarship1, index1)=>({
                ...(scholarship1 as ReceivedScholarship),
                id: `scholarship-${index1 + 1}`
            }));
    }
    featuredSchoolData = trackedFunction(this, async ()=>{
        const template1 = this.args.model.programInstance.tasProgramTemplate;
        const schools1 = await this.store.query<TasProgramTemplateInstitutionModel[]>('tas-program-template-institution', {
            filter: {
                'is-featured': true,
                'tas-program-template': template1.id
            },
            include: 'accredited-institution,unaccredited-institution,logo'
        });
        return schools1;
    });
    get featuredSchools() {
        return this.featuredSchoolData.value || [];
    }
    @action
    didUpdateCustomFields(customFields1: TasFieldsOrCustomFields, key1?: string) {
        switch(key1){
            case 'instanceCustomFields':
                // @ts-expect-error: not sure about this one
                this.instance.customFields = customFields1;
                break;
            case 'applicationCustomFields':
                // @ts-expect-error: not sure about this one
                this.application.customFields = customFields1;
                break;
            default:
                console.warn(`Unexpected fieldType: ${String(key1)}`);
                break;
        }
    }
    @action
    didUpdateAcceptedConditions(conditions1: ConditionModel[] = []) {
        this.acceptedConditions = conditions1;
    }
    @action
    didSelectSchool(model1?: TasUnifiedInstitutionModel) {
        this.selectedSchool = model1;
        setSingleValueForTasField(// @ts-expect-error: overload matches
        'SCHOOL_INSTITUTION_NAME', model1?.locationName, this.args.model.programInstance.fields);
        setSingleValueForTasField(// @ts-expect-error: overload matches
        'TAS_INSTITUTION_ID', model1?.institutionId, this.args.model.programInstance.fields);
        setSingleValueForTasField(// @ts-expect-error: overload matches
        'DAPIP_INSTITUTION_ID', model1?.dapipId, this.args.model.programInstance.fields);
    }
    setSemesterCode(date1: string) {
        const semesterCode1 = setSemesterCodeString(date1);
        setSingleValueForTasField('SEMESTER_CODE', semesterCode1, this.applicationFields);
    }
    @action
    saveStartDate(value1: string) {
        setSingleValueForTasField('COURSES_BEGIN_DATE', value1, this.applicationFields);
        if (this.programTemplate.semesterCodeIsVisible) {
            this.setSemesterCode(value1);
        }
        this.application.save();
        this.updateEligibility();
    }
    @action
    saveEndDate(value1: string) {
        setSingleValueForTasField('COURSES_END_DATE', value1, this.applicationFields);
        this.application.save();
        this.updateEligibility();
    }
    @action
    updateAppName(value1: string) {
        setSingleValueForTasField('APPLICATION_NAME', value1, this.applicationFields);
    }
    @action
    openEducationDrawer(course1?: TasCourseModel | null) {
        if (course1) {
            this.course = course1;
        } else {
            this.course = this.store.createRecord('tas-course', {
                tasApplication: this.application,
                fields: this.programTemplate.courseFieldsHash,
                customFields: this.programTemplate.courseCustomFields
            });
        }
        this.isEducationOpen = true;
    }
    @action
    closeEducationDrawer() {
        this.course = null;
        this.isEducationOpen = false;
    }
    @action
    toggleScholarshipDrawer() {
        this.isScholarshipOpen = !this.isScholarshipOpen;
    }
    requiredFieldsSet() {
        const applicationName1 = getSingleValueForTasField('APPLICATION_NAME', this.application.fields);
        const lock1 = getSingleValueForTasField('LOCK_EMPLOYEE_STATUS_BASED_ON', this.programTemplate.fields) as string;
        const hasLock1 = ()=>[
                'COURSE_START_DATE',
                'COURSE_END_DATE'
            ].includes(lock1);
        const requiredCustomFieldsFilled1 = ()=>{
            return this.instanceCustomFields.every((input1)=>{
                if (input1.required) {
                    return input1.values.length > 0;
                }
                return true;
            });
        };
        const courseField1 = lock1 === 'COURSE_START_DATE' ? 'COURSES_BEGIN_DATE' : ('COURSES_END_DATE' as TemplateFieldName);
        const requiredFields1 = {
            applicationName: applicationName1,
            ...(hasLock1() && {
                lockField: getSingleValueForTasField(courseField1, this.application.fields)
            })
        };
        const areFieldsFilled1 = Object.values(requiredFields1).every((field1)=>!!field1);
        return areFieldsFilled1 && requiredCustomFieldsFilled1();
    }
    get hasRequiredAttachments() {
        let hasRequiredAttachments1: boolean = false;
        switch(this.typeClassification){
            case 'TAS.ProgramType.2':
                hasRequiredAttachments1 = this.application.hasRequiredEvidence;
                break;
            case 'TAS.ProgramType.3':
                hasRequiredAttachments1 = this.application.hasPreApprovalAttachments;
                break;
            default:
                console.error(`Unhandled typeClassification: ${this.typeClassification}`);
                hasRequiredAttachments1 = false;
        }
        return hasRequiredAttachments1;
    }
    get canSubmitApplication() {
        const stateToApplyMap1 = {
            'TAS.ProgramType.2': 'TAS.ApplicationState.ATTEND',
            'TAS.ProgramType.3': 'TAS.ApplicationState.DEFINE_COURSES'
        };
        const requiredState1 = stateToApplyMap1[this.typeClassification as keyof typeof stateToApplyMap];
        return (!!this.application.activeCourses.length && this.hasAgreedAllConditions && this.hasRequiredAttachments && this.application.state === requiredState1 && this.requiredFieldsSet());
    }
    get adapterMethod() {
        const actionMap1 = {
            'TAS.ProgramType.2': 'requestCourseEvidence',
            'TAS.ProgramType.3': 'requestCoursesApproval'
        };
        return actionMap1[this.typeClassification as keyof typeof actionMap] || '';
    }
    get startOrEndDate(): string {
        if (this.lockStatus === 'start') {
            return getSingleValueForTasField('COURSES_BEGIN_DATE', this.application.fields);
        }
        if (this.lockStatus === 'end') {
            return getSingleValueForTasField('COURSES_END_DATE', this.application.fields);
        }
        return new Date().toLocaleDateString('en-CA');
    }
    get startDate(): string {
        return getSingleValueForTasField('COURSES_BEGIN_DATE', this.application.fields);
    }
    get endDate(): string {
        return getSingleValueForTasField('COURSES_END_DATE', this.application.fields);
    }
    get startDateConfig() {
        return getConfigForTasField('COURSES_BEGIN_DATE', this.programTemplate.fields);
    }
    get endDateConfig() {
        return getConfigForTasField('COURSES_END_DATE', this.programTemplate.fields);
    }
    get isClaimsFinancialsApplication() {
        return this.programTemplate.calculateTotalByClaimsFinancials;
    }
    @action
    submitApplication() {
        if (!this.canSubmitApplication) {
            return;
        }
        setSingleValueForTasField('PRE_APPROVED_TOTAL', this.isClaimsFinancialsApplication ? 0 : this.application.requestedTotal, this.applicationFields);
        this.onSave();
        this.submitTask.perform();
    }
    submitTask = dropTask(async ()=>{
        try {
            this.hasSubmitted = true;
            this.submitError = null;
            await this.store.adapterFor('tas-application')[this.adapterMethod](this.application);
            switch(this.typeClassification){
                case 'TAS.ProgramType.2':
                    this.router.transitionTo('authenticated.tas.confirmation', {
                        queryParams: {
                            tasType: 'two',
                            applicationId: this.application.id
                        }
                    });
                    break;
                case 'TAS.ProgramType.3':
                    this.router.transitionTo('authenticated.tas.confirmation', {
                        queryParams: {
                            tasType: 'three',
                            applicationId: this.application.id
                        }
                    });
                    break;
                default:
                    this.router.transitionTo('authenticated.tuition-assistance.dashboard');
            }
        } catch (error1) {
            console.error('Failed to submit application:', error1);
            this.submitError = error1;
        }
    });
    @action
    async onSave() {
        try {
            await this.application.save();
            await this.instance.save();
        } catch (error1) {
            console.error('Failed to save courses or application:', error1);
        }
    }
    @action
    onTrash() {
        this.isDeleteApplicationOpen = true;
    }
    @action
    toggleDeleteApplication() {
        this.isDeleteApplicationOpen = !this.isDeleteApplicationOpen;
    }
    @action
    toggleDeleteCourse(course1: TasCourseModel | null) {
        this.isDeleteCourseOpen = !this.isDeleteCourseOpen;
        if (course1) {
            this.course = course1;
        }
    }
    deleteApplication = dropTask(async ()=>{
        const adapter1 = this.store.adapterFor('tas-application');
        try {
            await adapter1.abandonCourse(this.application);
            // TAS.TODO: temporary routing until tas dashboard is complete
            this.router.transitionTo('authenticated.tuition-assistance.dashboard');
        } catch (e1) {
            console.error(e1);
        }
    });
    deleteCourse = dropTask(async ()=>{
        if (!this.course) {
            return;
        }
        try {
            if (this.course.isNew) {
                await this.course.rollbackAttributes();
            } else {
                await this.course.destroyRecord();
            }
            this.isDeleteCourseOpen = false;
            this.course = null;
        } catch (e1) {
            console.error(e1);
        }
    });
    // TAS.TODO: need custom field validation
    get customFieldValidationErrors() {
        return [];
    }
    @action
    openDeleteScholarship(scholarship1: ReceivedScholarship) {
        this.scholarshipToDelete = scholarship1;
        this.isDeleteScholarshipOpen = true;
    }
    @action
    closeDeleteScholarship() {
        this.scholarshipToDelete = null;
        this.isDeleteScholarshipOpen = false;
    }
    @action
    deleteScholarship() {
        let existingScholarships1 = getAllValuesForTasField('SCHOLARSHIPS_RECEIVED', this.args.model.application.fields);
        let updatedScholarships1 = existingScholarships1.filter((scholarship1: FieldValue)=>(scholarship1 as Record<string, unknown>)?.scholarshipName !== this.scholarshipToDelete?.scholarshipName) as ReceivedScholarship[];
        this.applicationFields['SCHOLARSHIPS_RECEIVED'] = {
            values: updatedScholarships1.filter((scholarship1)=>scholarship1 !== null)
        };
        this.args.model.application.fields = this.applicationFields;
        this.isDeleteScholarshipOpen = false;
    }
    @action
    openEditScholarship(scholarship1: ReceivedScholarship) {
        this.scholarshipToEdit = scholarship1;
        this.isEditScholarshipOpen = true;
    }
    @action
    closeEditScholarship() {
        this.scholarshipToEdit = null;
        this.isEditScholarshipOpen = false;
    }
    @action
    updateName(value1: string) {
        this.scholarshipToEdit!.scholarshipName = value1;
    }
    @action
    updateAmount(value1: string) {
        this.scholarshipToEdit!.scholarshipAmount = currencyStringToNumber(value1);
    }
    @action
    async checkForMissingFields() {
        if (this.scholarshipToEdit?.scholarshipName === '' || this.scholarshipToEdit?.scholarshipAmount === 0) {
            this.scholarshipEditError = 'A required field is missing';
        } else {
            this.scholarshipEditError = '';
        }
    }
    @action
    async updateScholarship() {
        await this.checkForMissingFields();
        if (this.scholarshipEditError !== '') {
            return;
        }
        const updatedScholarships1 = this.scholarships.map((scholarship1)=>scholarship1.id === this.scholarshipToEdit?.id ? {
                scholarshipName: this.scholarshipToEdit.scholarshipName,
                scholarshipAmount: this.scholarshipToEdit.scholarshipAmount
            } : scholarship1);
        this.args.model.application.fields['SCHOLARSHIPS_RECEIVED'] = {
            values: updatedScholarships1
        };
        this.scholarshipToEdit = null;
        this.closeEditScholarship();
    }
    @action
    getValue(values1: string[] | boolean[]): string {
        return values1[0]?.toString() || '';
    }
    @action
    getAllValuesForField(fieldName1: keyof TASProgramTemplateModelFieldsSignature) {
        return getAllValuesForTasField(fieldName1 as keyof TASProgramInstanceModelFieldsSignature, this.instance.fields) as string[];
    }
    @action
    updateValueForField(fieldName1: FieldName, fields1: TasApplicationModel['fields'] | TASProgramInstanceModel['fields'], type1: string | null, value1: string[] | string | number, event1?: Event | undefined) {
        let valueToSet1 = Array.isArray(value1) ? value1[0] : value1;
        // @ts-expect-error: input mask types are not correct
        if (event1?.target?.inputmask?.userOptions?.alias === 'currency') {
            // @ts-expect-error: input mask types are not correct
            valueToSet1 = event1.target.inputmask?.unmaskedvalue?.() * 100;
        }
        if (type1 === 'number') {
            valueToSet1 = Math.round(Number(valueToSet1));
        }
        // @ts-expect-error: fieldName
        setSingleValueForTasField(fieldName1, valueToSet1, fields1);
    // this.setApprovedCourseTotalBeforeSave();
    }
    inputClasses = {
        base: 'w-full mb-6'
    };
    static{
        template1(`
    <TasPageHeader>
      <:title as |title|>
        {{! TAS.TODO: temporary routing until tas dashboard is complete }}
        <title.BackLink @label="Back" @route="authenticated.tuition-assistance.dashboard">
          {{t "sidebar.tuition_assistance"}}
        </title.BackLink>
      </:title>
      <:actions as |actions|>
        <actions.Button data-test-save-application {{on "click" this.onSave}}>
          {{t "save"}}
        </actions.Button>
        <actions.IconButton
          data-test-delete-application
          {{on "click" this.onTrash}}
          @type="trash"
        />
      </:actions>
    </TasPageHeader>

    <HStack>
      <VStack class="lg:!w-2/3 md:!w-1/2">
        <Section>
          <:header>
            <div>{{t "tas.application.type_two.new.application"}}</div>
            <div class="font-normal">
              {{t "tas.application.type_two.new.required_items"}}
              <span class="text-error-400 text-xl">*</span>
            </div>
          </:header>
          <:body>
            <div class="flex justify-between items-center w-full py-4">
              {{#if this.startDateConfig.visible}}
                <div class="w-5/12">
                  <label for="start" class="text-gray-600 font-semibold">
                    {{t "tas.application.type_two.new.start_date"}}
                    {{#if this.startDateConfig.required}}
                      <span class="text-error-400">*</span>
                    {{/if}}
                  </label>
                  <Input
                    @value={{this.startDate}}
                    @onInput={{this.saveStartDate}}
                    @type="date"
                    id="start"
                  />
                </div>
              {{/if}}
              {{#if this.endDateConfig.visible}}
                <div class="w-5/12">
                  <label for="end" class="text-gray-600 font-semibold">
                    {{t "tas.application.type_two.new.end_date"}}
                    {{#if this.endDateConfig.required}}
                      <span class="text-error-400">*</span>
                    {{/if}}
                  </label>
                  <Input
                    @value={{this.endDate}}
                    @onInput={{this.saveEndDate}}
                    @type="date"
                    id="end"
                  />
                </div>
              {{/if}}
            </div>
            {{#unless this.eligibility}}
              <TioAlert @type="error" @allowDismiss={{false}} class="mb-4">
                <:header>
                  <p class="font-bold" data-test-eligibility-error>
                    {{t "start_date_eligibility.errorHeader" startEndSubmission=this.lockStatus}}
                  </p>
                </:header>
                <:body>
                  <p class="text-sm">
                    {{t "start_date_eligibility.errorText"}}
                  </p>
                </:body>
              </TioAlert>
            {{/unless}}
            <Divider class="my-2" />
            <div class="flex items-center w-full py-4">
              <div class="w-1/2">
                <h2 class="text-gray-600 font-semibold">{{t "tas.application.type_two.new.name"}}
                  <span class="text-error-400">*</span>
                </h2>
              </div>
              <div class="w-1/2">
                <label for="application-name" class="sr-only">
                  {{t "tas.application.type_two.new.name"}}!!
                </label>
                <Input
                  size="lg"
                  data-test-application-name
                  id="application-name"
                  @value={{getSingleValueForTasField "APPLICATION_NAME" @model.application.fields}}
                  class="!bg-transparent"
                  @onChange={{this.updateAppName}}
                />
              </div>
            </div>
            <Divider class="mb-4" />

            {{#each this.programFormFieldsForTypeTwoAndThree as |programField|}}
              {{#if (eq programField.name "SCHOOL_INSTITUTION_NAME")}}
                <SchoolSelect
                  @programTemplate={{this.programTemplate}}
                  @labelClass="!text-gray-600 font-semibold !text-base w-1/2"
                  @selected={{this.selectedSchool}}
                  @onChange={{this.didSelectSchool}}
                  class="mb-2 flex flex-wrap justify-between gap-y-4 items-center [&>div]:w-1/2 [&>div]:border [&>div]:border-default-400 [&>div]:rounded-lg"
                />
                <Divider class="my-4" />

              {{else}}
                <ProgramFormField
                  @name={{programField.name}}
                  @programTemplate={{this.instance.tasProgramTemplate}}
                  @labelClass="!text-gray-600 font-semibold !text-base w-1/2"
                  class="flex flex-wrap justify-between gap-y-4 items-center [&>label]:w-1/2 [&>div]:w-1/2
                    {{if (eq programField.type 'date') '[&>div]:w-1/4' '[&>div]:w-1/2'}}"
                  as |field|
                >
                  {{#if field.isFieldOptionPicklist}}
                    <Select
                      data-test={{field.name}}
                      id={{field.inputId}}
                      {{! @glint-expect-error: how do we reconcile readonly }}
                      @items={{field.fieldOptions}}
                      @selectionMode="single"
                      @selectedKeys={{this.getAllValuesForField field.name}}
                      @onSelectionChange={{fn
                        this.updateValueForField
                        field.name
                        this.instance.fields
                        programField.type
                      }}
                      disabled={{programField.disabled}}
                      {{!-- @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}} --}}
                    />
                  {{else if (eq programField.mask "currency")}}
                    <Input
                      data-test={{field.name}}
                      id={{field.inputId}}
                      @value="{{divide
                        (or (getSingleValueForTasField field.name this.instance.fields) 0)
                        100
                      }}"
                      @onChange={{fn
                        this.updateValueForField
                        field.name
                        this.instance.fields
                        programField.type
                      }}
                      disabled={{programField.disabled}}
                      {{inputmask alias="currency" prefix="\$" unmaskAsNumber=false digits="2"}}
                      {{!-- @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}} --}}
                    />
                  {{else}}
                    <Input
                      data-test={{field.name}}
                      id={{field.inputId}}
                      @value={{getSingleValueForTasField field.name this.instance.fields}}
                      @onChange={{fn
                        this.updateValueForField
                        field.name
                        this.instance.fields
                        programField.type
                      }}
                      type={{programField.type}}
                      disabled={{programField.disabled}}
                      {{!-- @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}} --}}
                    />
                  {{/if}}
                  <Divider class="mb-4" />
                </ProgramFormField>
              {{/if}}
            {{/each}}

            <TuitionAssistanceFormsCustomFieldsForProvince
              @province="PROGRAM"
              @customFields={{this.instanceCustomFields}}
              @didUpdateFields={{this.didUpdateCustomFields}}
              @formValidationErrors={{this.customFieldValidationErrors}}
              @customFieldModel="instanceCustomFields"
            />

            <TuitionAssistanceFormsCustomFieldsForProvince
              @province="APPLICATION"
              @customFields={{this.applicationCustomFields}}
              @didUpdateFields={{this.didUpdateCustomFields}}
              @formValidationErrors={{this.customFieldValidationErrors}}
              @customFieldModel="applicationCustomFields"
            />

          </:body>
        </Section>
        <Section>
          <:header>
            <div class="flex justify-between items-center">
              <p>{{this.expenseTypeField}}</p>
              <Button
                class="text-ocean-600 text-base font-normal border-ocean-600"
                @appearance="outlined"
                data-test-add-education-drawer
                {{on "click" (fn this.openEducationDrawer null)}}
              >
                <Plus class="h-6 w-6 mr-2" />
                {{or this.expenseTypeField (t "tas.application.type_two.new.education")}}
              </Button>
            </div>
          </:header>
          <:body>
            <p>
              {{t "tas.application.type_two.new.expense_type_description"}}
            </p>
            <ActionableList @striped={{false}} as |l|>
              {{#each @model.application.activeCourses as |course|}}
                <l.Row>
                  <l.Term class="md:w-1/3">{{course.courseName}}</l.Term>
                  <l.Details>
                    <l.Item class="justify-self-center md:justify-self-start">
                      {{safeFormatNumber
                        (divide course.courseTotal 100)
                        style="currency"
                        currency="USD"
                      }}
                    </l.Item>
                    <l.Item
                      class="justify-self-center md:justify-self-start font-semibold"
                    >{{course.courseGrade}}</l.Item>
                  </l.Details>
                  <l.Action>
                    <button
                      type="button"
                      data-test-delete-course
                      {{on "click" (fn this.toggleDeleteCourse course)}}
                    ><XCircle class="h-6 w-6" /></button>
                    <button
                      type="button"
                      data-test-edit-education-drawer
                      {{on "click" (fn this.openEducationDrawer course)}}
                    ><PencilSquare class="h-6 w-6" /></button>
                  </l.Action>
                </l.Row>
              {{/each}}
            </ActionableList>
          </:body>
        </Section>
        {{! Add Scholarships }}
        <Section>
          <:header>
            <div class="flex justify-between items-center">
              <p>
                {{t "tas.new.scholarships.default"}}
                <span class="text-normal">{{t "tas.new.scholarships.optional"}}</span>
              </p>
              <Button
                @appearance="outlined"
                class="text-ocean-600 hover:text-ocean-700 border-ocean-600 text-base font-normal"
                data-test-add-scholarship-drawer
                {{on "click" this.toggleScholarshipDrawer}}
              >
                <Plus class="h-6 w-6 mr-2" />
                {{t "tas.new.scholarships.default"}}
              </Button>
            </div>
          </:header>
          <:body>
            <p>{{t "tas.new.scholarships.please_enter_any_scholarships"}}</p>
            <ActionableList @striped={{false}} as |l|>
              {{#each this.scholarships as |scholarship|}}
                <l.Row>
                  <l.Term>{{scholarship.scholarshipName}}</l.Term>
                  <l.Details>
                    <l.Item class="justify-self-center">
                      {{safeFormatNumber
                        (divide scholarship.scholarshipAmount 100)
                        style="currency"
                        currency="USD"
                      }}
                    </l.Item>
                  </l.Details>
                  <l.Action>
                    <button type="button" data-test-delete-scholarship>
                      <XCircle
                        class="h-6 w-6 text-gray-700"
                        {{on "click" (fn this.openDeleteScholarship scholarship)}}
                      />
                    </button>
                    <button type="button" data-test-edit-scholarship>
                      <PencilSquare
                        class="h-6 w-6 text-gray-700"
                        {{on "click" (fn this.openEditScholarship scholarship)}}
                      />
                    </button>
                  </l.Action>
                </l.Row>
              {{/each}}
            </ActionableList>
          </:body>
        </Section>

        {{! Upload Attachments}}
        <Section>
          <:header>{{t "attachments"}}</:header>
          <:body>
            <VStack>
              <p>
                {{t "tas.application.type_two.new.attachment_description"}}
                {{#if this.requireAttachmentUploads}}
                  <span class="text-error-400 text-xl">*</span>
                {{/if}}
              </p>
              <UploadAttachments
                @programTemplate={{this.programTemplate}}
                @classification={{this.attachmentClassificationString}}
                @attachable={{@model.application}}
                @attachableType="tasApplication"
                @useDropzone={{true}}
                @instructions={{this.attachmentsListDescription}}
                @items={{this.attachmentsListItems}}
                {{!-- @disabled={{this.disableAttachmentUpload}} --}}
              />
            </VStack>
          </:body>
        </Section>
      </VStack>
      <Section class="w-full lg:!w-1/3 md:!w-1/2 sticky top-0">
        <:header>{{t "tas.benefits_summary.title"}}</:header>
        <:body>
          <LimitsSummary
            @application={{@model.application}}
            @employee={{@model.programInstance.employee}}
            @labelClass="col-span-5"
            @valueClass="col-span-2 text-violet-800"
            class="my-4"
            as |Summary|
          >
            <div class="w-full grid grid-cols-7 gap-2 mt-3">
              {{#unless Summary.isUnlimitedMaximumAnnualBenefit}}
                <Summary.MaxAnnualBenefitLineItem />
              {{/unless}}

              {{#if Summary.hasLimitsErrorForEmployee}}
                <Summary.RemainingBenefitLineItem />
              {{/if}}
              <Summary.ApplicationAmountRequestedWithoutScholarshipsLineItem
                @valueClass="text-tio-gray-800 col-span-2"
              />
              {{#if this.programTemplate.scholarshipsAreVisible}}
                <Summary.ScholarshipAmountLineItem
                  @valueClass="text-tio-gray-800 !font-normal col-span-2"
                />
              {{/if}}
              <Summary.LimitsAlertForEmployeeIfApplicable class="text-sm col-span-full" />
              <ReimbursementCalculationIfApplicable @application={{@model.application}} />
              <Divider class="my-2 col-span-7" />
              <Summary.ApplicationAmountRequestedLineItem />
            </div>

            {{! Required Items Section - ASIDE }}
            <section class="my-5">
              {{#if (or this.requireAttachmentUploads this.conditionsForSubmit.length)}}
                <header
                  class="border-y bg-tio-gray-25 flex items-center gap-2 py-2 px-5 text-sm font-medium tracking-wide -mx-4"
                >
                  <ClipboardDocumentList class="h-8 w-8" />
                  <span>{{t "tas.benefits_summary.required_items"}}</span>
                </header>
              {{/if}}
              {{#if this.requireAttachmentUploads}}
                <div>
                  <div class="flex flex-row justify-between items-center">
                    <p>{{t "tas.benefits_summary.attachments"}}
                      <span class="text-error-400 ml-1">
                        *
                      </span>
                    </p>
                    <div>
                      <UploadAttachments
                        @programTemplate={{this.programTemplate}}
                        @classification={{this.attachmentClassificationString}}
                        @attachable={{@model.application}}
                        @attachableType="tasApplication"
                        @useDropzone={{false}}
                        @iconOnlyUpload={{true}}
                        @hideAttachments={{true}}
                        @hideInstructions={{true}}
                        {{!-- @disabled={{this.disableAttachmentUpload}} --}}
                      />
                    </div>
                  </div>
                  <div class="flex items-center gap-3 -mt-1">
                    {{#if this.hasRequiredAttachments}}
                      <CheckCircleSolid class="h-6 w-6 text-violet-500" />
                    {{else}}
                      <CheckCircle class="h-6 w-6 text-tio-gray-200" />
                    {{/if}}
                    <p>{{join ", " this.attachmentsListItems}}</p>
                  </div>
                </div>
              {{/if}}

              {{#if this.conditionsForSubmit.length}}
                <AcceptConditions
                  @conditions={{this.conditionsForSubmit}}
                  @onChange={{this.didUpdateAcceptedConditions}}
                />
              {{/if}}
            </section>

            <div class="py-4">
              {{#if this.canSubmitApplication}}
                <Button
                  class="flex justify-center w-full py-3 px-6 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg hover:bg-ocean-400"
                  data-test-submit-application
                  {{on "click" this.submitApplication}}
                >
                  {{t "tas.benefits_summary.submit_application"}}
                </Button>
              {{else}}
                <MissingRequirements />
              {{/if}}
              <p class="text-sm font-light text-center">
                {{t "tas.benefits_summary.helper_text"}}
              </p>
              {{#if this.submitError}}
                <TioErrorMessages
                  @showErrors={{true}}
                  @error={{this.submitError}}
                  class="text-sm"
                  @icon="warning"
                />
              {{/if}}
            </div>
          </LimitsSummary>
        </:body>
      </Section>
    </HStack>

    {{#if this.isEducationOpen}}
      <AddEducationDrawer
        @isOpen={{this.isEducationOpen}}
        @onClose={{this.closeEducationDrawer}}
        @application={{@model.application}}
        @course={{this.course}}
        @header={{this.expenseTypeField}}
      />
    {{/if}}
    <AddScholarshipsDrawer
      @isOpen={{this.isScholarshipOpen}}
      @onClose={{this.toggleScholarshipDrawer}}
      @application={{@model.application}}
    />

    {{! Benefits Summary Window}}

    {{! Delete Application Modal }}
    <Modal
      @isOpen={{this.isDeleteApplicationOpen}}
      @onClose={{this.toggleDeleteApplication}}
      data-test-delete-application-modal
      as |m|
    >
      <m.Header>{{t "tas.application.type_two.new.delete_application"}}</m.Header>
      <m.Body>
        <p>{{t "tas.application.type_two.new.confirm_application_delete"}}</p>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.deleteApplication.isRunning}}
          @onCancel={{this.toggleDeleteApplication}}
          @onSubmit={{this.deleteApplication.perform}}
        />
      </m.Footer>
    </Modal>

    <Modal
      @isOpen={{this.isDeleteCourseOpen}}
      @onClose={{fn this.toggleDeleteCourse null}}
      data-test-delete-course-modal
      as |m|
    >
      <m.Header>{{t "tas.application.type_two.new.delete_course"}}</m.Header>
      <m.Body>
        <p>{{t "tas.application.type_two.new.confirm_delete_expense"}}:</p>
        <p class="font-semibold px-2">{{this.course.courseName}}</p>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.deleteCourse.isRunning}}
          @onCancel={{fn this.toggleDeleteCourse null}}
          @onSubmit={{this.deleteCourse.perform}}
        />
      </m.Footer>
    </Modal>
    {{! Delete Scholarship Modal }}
    <Modal
      @isOpen={{this.isDeleteScholarshipOpen}}
      @onClose={{this.closeDeleteScholarship}}
      data-test-delete-scholarship-modal
      as |m|
    >
      <m.Header>{{t "tas.application.type_two.new.delete_scholarship"}}</m.Header>
      {{#let this.scholarshipToDelete as |scholarship|}}
        <m.Body>
          <p>{{t "tas.application.type_two.new.confirm_scholarship_delete"}}</p>
          <div class="p-8 flex w-2/3 justify-between">
            <p class="font-medium">{{scholarship.scholarshipName}}</p>
            <p class="font-medium">{{safeFormatNumber
                (divide scholarship.scholarshipAmount 100)
                style="currency"
                currency="USD"
              }}</p>
          </div>
        </m.Body>
      {{/let}}
      <m.Footer>
        <Button @appearance="minimal" class="mr-4" {{on "click" this.closeDeleteScholarship}}>
          {{t "cancel"}}
        </Button>
        <Button @intent="danger" {{on "click" this.deleteScholarship}}>
          {{t "delete"}}
        </Button>
      </m.Footer>
    </Modal>
    {{! Edit Scholarship Modal }}
    <Modal
      @isOpen={{this.isEditScholarshipOpen}}
      @onClose={{this.closeEditScholarship}}
      data-test-edit-scholarship-modal
      as |m|
    >
      <m.Header>{{t "tas.application.type_two.new.edit_scholarship"}}</m.Header>
      <m.Body>
        <div class="flex flex-col w-full">
          <label for="scholarship_name4" class="font-semibold text-gray-600">
            {{t "tas.add_scholarships_drawer.scholarship_name"}}
            <span class="text-error-400">*</span>
          </label>
          <Input
            @value={{this.scholarshipToEdit.scholarshipName}}
            @classes={{this.inputClasses}}
            @onChange={{this.updateName}}
            @isRequired={{true}}
            data-test-edit-scholarship-name
          />
          <label for="scholarship_amount4" class="font-semibold text-gray-600">
            {{t "tas.add_scholarships_drawer.scholarship_amount"}}
            <span class="text-error-400">*</span>
          </label>
          <Input
            @value="{{safeFormatNumber
              (divide this.scholarshipToEdit.scholarshipAmount 100)
              style="currency"
              currency="USD"
            }}"
            @classes={{this.inputClasses}}
            @onChange={{this.updateAmount}}
            @isRequired={{true}}
            data-test-edit-scholarship-amount
            {{inputmask alias="currency" prefix="\$" unmaskAsNumber=true digits="2"}}
          />
          <p class="text-danger">{{this.scholarshipEditError}}</p>
        </div>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onCancel={{this.closeEditScholarship}}
          @onSubmit={{this.updateScholarship}}
        />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
