import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { all, dropTask } from 'ember-concurrency';
import { Button } from 'tio-ui/components/buttons';
import { currencyStringToNumber } from 'tio-common/utils/format';
import { Divider } from 'tio-ui/components/utilities';
import { fn } from '@ember/helper';
import { Input } from 'tio-ui/components/forms';
import { Modal, Footer } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { or } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { getAllValuesForTasField, getConfigForTasField, getSingleValueForTasField, setSingleValueForTasField, type FieldValue, type TemplateFieldName } from 'tio-common/utils/tuition-assistance/fields';
import AcceptConditions from 'tio-employee/components/tuition-assistance/forms/accept-conditions';
import ActionableList from 'tio-ui/components/actionable-list';
import AddEducationDrawer from 'tio-employee/components/tas/add-education-drawer';
import AddScholarshipsDrawer from 'tio-employee/components/tas/add-scholarships-drawer';
import CheckCircle from 'ember-static-heroicons/components/outline-24/check-circle';
import CheckCircleSolid from 'ember-static-heroicons/components/solid-24/check-circle';
import ClipboardDocumentList from 'ember-static-heroicons/components/outline-24/clipboard-document-list';
import Component from '@glimmer/component';
import divide from 'ember-math-helpers/helpers/div';
import inputmask from 'tio-common/modifiers/inputmask';
import LimitsSummary from 'tio-common/components/tuition-assistance/limits-summary';
import MissingRequirements from 'tio-employee/components/tas/missing-requirements';
import PencilSquare from 'ember-static-heroicons/components/outline-24/pencil-square';
import Plus from 'ember-static-heroicons/components/solid-24/plus';
import ReimbursementCalculationIfApplicable from 'tio-common/components/tuition-assistance/reimbursement-calculation-if-applicable';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import TasPageHeader from 'tio-employee/components/tas/page-header';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import TuitionAssistanceFormsCustomFieldsForProvince from 'tio-common/components/tuition-assistance/forms/custom-fields-for-province';
import TioAlert from 'tio-common/components/tio/alert';
import type { ReceivedScholarship } from 'tio-common/types/tuition-assistance';
import type { TASProgramTemplateModelFieldsSignature } from 'tio-common/models/tas-program-template';
import type ConditionModel from 'tio-common/models/condition';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TasCourseModel from 'tio-common/models/tas-course';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
import UploadAttachments from 'tio-common/components/tuition-assistance/forms/upload-attachments';
import XCircle from 'ember-static-heroicons/components/outline-24/x-circle';
import { HStack, Section, VStack } from 'tio-ui/components/layout';
type ProgramInstanceFieldName = keyof TASProgramTemplateModelFieldsSignature;
export type FormField = {
    name: ProgramInstanceFieldName;
    mask: string | null;
    type: string | null;
    rules: {
        type: string;
        typeError?: string;
    };
};
export interface NewTypeFourSignature {
    Args: {
        model: {
            application: TasApplicationModel;
            programInstance: TASProgramInstanceModel;
        };
    };
}
interface Scholarship extends ReceivedScholarship {
    id?: string;
}
export default class NewTypeTwoOrThreeComponent extends Component<NewTypeFourSignature> {
    programTemplate: TasProgramTemplateModel;
    constructor(owner1: unknown, args1: NewTypeFourSignature['Args']){
        super(owner1, args1);
        const { programInstance: programInstance1 } = args1.model;
        this.programTemplate = programInstance1.tasProgramTemplate;
        this.scholarshipToDelete = null;
        this.scholarshipToEdit = null;
        this.eligibility = this.updateEligibility() as unknown as boolean;
    }
    @tracked
    acceptedConditions: ConditionModel[] = [];
    @tracked
    course: TasCourseModel | null = null;
    @tracked
    eligibility: boolean;
    @tracked
    hasSubmitted = false;
    @tracked
    isDeleteApplicationOpen = false;
    @tracked
    isDeleteCourseOpen = false;
    @tracked
    isDeleteScholarshipOpen = false;
    @tracked
    isEditScholarshipOpen = false;
    @tracked
    isEducationOpen = false;
    @tracked
    isOpen = false;
    @tracked
    isScholarshipOpen = false;
    @tracked
    scholarshipEditError = '';
    @tracked
    scholarshipToDelete: Scholarship | null;
    @tracked
    scholarshipToEdit: Scholarship | null;
    @tracked
    submitError = null;
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @service
    router: RouterService;
    calculateEligibility(periodOfEligibility1: [string, string][]) {
        if (periodOfEligibility1.length === 0) {
            return false;
        }
        if (periodOfEligibility1.length === 1) {
            const programEligibility1 = periodOfEligibility1.flat();
            return (this.startOrEndDate >= programEligibility1[0]! && this.startOrEndDate <= programEligibility1[1]!);
        }
        for (const eligibility1 of periodOfEligibility1){
            if (this.startOrEndDate >= eligibility1[0] && this.startOrEndDate <= eligibility1[1]) {
                return true;
            }
        }
    }
    async updateEligibility(): Promise<void> {
        const { id: participantId1 } = this.instance.employee.tasParticipant;
        const { code: code1 } = this.instance.tasProgramTemplate;
        try {
            const [dynamicEligibility1, programEligibility1] = await Promise.all([
                this.store.adapterFor('tas-participant').dynamicEligibility(participantId1, code1),
                this.store.adapterFor('tas-participant').periodOfEligibility(participantId1, code1)
            ]);
            const eligibilityResult1 = dynamicEligibility1 && this.calculateEligibility(programEligibility1);
            this.eligibility = eligibilityResult1;
            return eligibilityResult1;
        } catch (error1) {
            console.error('Error fetching eligibility:', error1);
            this.eligibility = false;
        }
    }
    get application() {
        return this.args.model.application;
    }
    get instance() {
        return this.args.model.programInstance;
    }
    get applicationFields(): TasApplicationModel['fields'] {
        return this.application.fields;
    }
    get applicationName() {
        return getSingleValueForTasField('APPLICATION_NAME', this.applicationFields);
    }
    get educationSectionTitle() {
        return this.application.expenseType || 'Education';
    }
    get typeClassification() {
        return this.programTemplate.typeClassification || '';
    }
    get conditionsForSubmit() {
        return this.programTemplate.applicationApproveCoursesConditions || [];
    }
    get hasAgreedAllConditions() {
        if (!this.conditionsForSubmit.length) {
            return true;
        }
        return this.conditionsForSubmit.length === this.acceptedConditions.length;
    }
    saveAgreementsForConditions = dropTask(async ()=>{
        const user1 = this.sessionContext.user;
        const agreements1 = this.acceptedConditions.map((condition1)=>{
            return this.store.createRecord('agreement', {
                user: user1,
                condition: condition1
            });
        });
        const promises1 = agreements1.map((agreement1)=>{
            return agreement1.save();
        });
        const saved1 = await all(promises1);
        return saved1;
    });
    get requireAttachmentUploads() {
        return this.programTemplate.requireAttachmentsForCourseApproval;
    }
    get attachmentClassificationString() {
        return 'COURSE_APPROVAL_DOCUMENTS';
    }
    get attachmentsListDescription() {
        const attachmentString1 = `${this.attachmentClassificationString}_ATTACHMENT_FIELD` as keyof TASProgramTemplateModelFieldsSignature;
        return (getAllValuesForTasField(attachmentString1, this.programTemplate.fields).join(', ') || 'None Required');
    }
    get applicationCustomFields() {
        return this.application.customFields;
    }
    get lockStatus() {
        const lock1 = getSingleValueForTasField('LOCK_EMPLOYEE_STATUS_BASED_ON', this.programTemplate.fields) as string;
        const lockType1: Record<string, string> = {
            COURSE_START_DATE: 'start',
            COURSE_END_DATE: 'end'
        };
        return lockType1[lock1] || 'start';
    }
    get expenseTypeField() {
        let expenseType1 = getSingleValueForTasField('EXPENSE_TYPE', this.programTemplate.fields) as string;
        return expenseType1 || 'Education';
    }
    get scholarships() {
        let savedScholarships1 = getAllValuesForTasField('SCHOLARSHIPS_RECEIVED', this.application.fields);
        return savedScholarships1.map((scholarship1, index1)=>({
                ...(scholarship1 as ReceivedScholarship),
                id: `scholarship-${index1 + 1}`
            }));
    }
    @action
    didUpdateCustomFields(customFields1: TasApplicationModel['customFields']) {
        this.args.model.application.customFields = customFields1;
    }
    @action
    didUpdateAcceptedConditions(conditions1: ConditionModel[] = []) {
        this.acceptedConditions = conditions1;
    }
    @action
    saveStartDate(value1: string) {
        setSingleValueForTasField('COURSES_BEGIN_DATE', value1, this.applicationFields);
        this.application.save();
        this.updateEligibility();
    }
    @action
    saveEndDate(value1: string) {
        setSingleValueForTasField('COURSES_END_DATE', value1, this.applicationFields);
        this.application.save();
        this.updateEligibility();
    }
    @action
    updateAppName(value1: string) {
        setSingleValueForTasField('APPLICATION_NAME', value1, this.applicationFields);
    }
    @action
    openEducationDrawer(course1?: TasCourseModel | null) {
        if (course1) {
            this.course = course1;
        } else {
            this.course = this.store.createRecord('tas-course', {
                tasApplication: this.application,
                fields: this.programTemplate.courseFieldsHash,
                customFields: this.programTemplate.courseCustomFields
            });
        }
        this.isEducationOpen = true;
    }
    @action
    closeEducationDrawer() {
        this.course = null;
        this.isEducationOpen = false;
    }
    @action
    toggleScholarshipDrawer() {
        this.isScholarshipOpen = !this.isScholarshipOpen;
    }
    requiredFieldsSet() {
        const applicationName1 = getSingleValueForTasField('APPLICATION_NAME', this.application.fields);
        const lock1 = getSingleValueForTasField('LOCK_EMPLOYEE_STATUS_BASED_ON', this.programTemplate.fields) as string;
        const hasLock1 = ()=>[
                'COURSE_START_DATE',
                'COURSE_END_DATE'
            ].includes(lock1);
        const courseField1 = lock1 === 'COURSE_START_DATE' ? 'COURSES_BEGIN_DATE' : ('COURSES_END_DATE' as TemplateFieldName);
        const requiredFields1 = {
            applicationName: applicationName1,
            ...(hasLock1() && {
                lockField: getSingleValueForTasField(courseField1, this.application.fields)
            })
        };
        const areFieldsFilled1 = Object.values(requiredFields1).every((field1)=>!!field1);
        return areFieldsFilled1;
    }
    get hasRequiredAttachments() {
        return this.application.hasPreApprovalAttachments;
    }
    get canSubmitApplication() {
        return (!!this.application.activeCourses.length && this.hasAgreedAllConditions && this.hasRequiredAttachments && this.application.state === 'TAS.ApplicationState.DEFINE_COURSES' && this.requiredFieldsSet());
    }
    get startOrEndDate(): string {
        if (this.lockStatus === 'start') {
            return getSingleValueForTasField('COURSES_BEGIN_DATE', this.application.fields);
        }
        if (this.lockStatus === 'end') {
            return getSingleValueForTasField('COURSES_END_DATE', this.application.fields);
        }
        return new Date().toLocaleDateString('en-CA');
    }
    get startDate(): string {
        return getSingleValueForTasField('COURSES_BEGIN_DATE', this.application.fields);
    }
    get startDateConfig() {
        return getConfigForTasField('COURSES_BEGIN_DATE', this.programTemplate.fields);
    }
    get endDate(): string {
        return getSingleValueForTasField('COURSES_END_DATE', this.application.fields);
    }
    get endDateConfig() {
        return getConfigForTasField('COURSES_END_DATE', this.programTemplate.fields);
    }
    get isClaimsFinancialsApplication() {
        return this.programTemplate.calculateTotalByClaimsFinancials;
    }
    @action
    submitApplication() {
        if (!this.canSubmitApplication) {
            return;
        }
        setSingleValueForTasField('PRE_APPROVED_TOTAL', this.isClaimsFinancialsApplication ? 0 : this.application.requestedTotal, this.applicationFields);
        this.onSave();
        this.submitTask.perform();
    }
    submitTask = dropTask(async ()=>{
        try {
            this.hasSubmitted = true;
            this.submitError = null;
            await this.store.adapterFor('tas-application').requestCoursesApproval(this.application);
            this.router.transitionTo('authenticated.tas.confirmation', {
                queryParams: {
                    tasType: 'four',
                    applicationId: this.application.id
                }
            });
        } catch (error1) {
            console.error('Failed to submit application:', error1);
            this.submitError = error1;
        }
    });
    @action
    async onSave() {
        try {
            await this.application.save();
        } catch (error1) {
            console.error('Failed to save courses or application:', error1);
        }
    }
    @action
    onTrash() {
        this.isDeleteApplicationOpen = true;
    }
    @action
    toggleDeleteApplication() {
        this.isDeleteApplicationOpen = !this.isDeleteApplicationOpen;
    }
    @action
    toggleDeleteCourse(course1: TasCourseModel | null) {
        this.isDeleteCourseOpen = !this.isDeleteCourseOpen;
        if (course1) {
            this.course = course1;
        }
    }
    deleteApplication = dropTask(async ()=>{
        const adapter1 = this.store.adapterFor('tas-application');
        try {
            await adapter1.abandonCourse(this.application);
            // TAS.TODO: temporary routing until tas dashboard is complete
            this.router.transitionTo('authenticated.tuition-assistance.dashboard');
        } catch (e1) {
            console.error(e1);
        }
    });
    deleteCourse = dropTask(async ()=>{
        if (!this.course) {
            return;
        }
        try {
            if (this.course.isNew) {
                await this.course.rollbackAttributes();
            } else {
                await this.course.destroyRecord();
            }
            this.isDeleteCourseOpen = false;
            this.course = null;
        } catch (e1) {
            console.error(e1);
        }
    });
    // TAS.TODO: need custom field validation
    get customFieldValidationErrors() {
        return [];
    }
    @action
    openDeleteScholarship(scholarship1: ReceivedScholarship) {
        this.scholarshipToDelete = scholarship1;
        this.isDeleteScholarshipOpen = true;
    }
    @action
    closeDeleteScholarship() {
        this.scholarshipToDelete = null;
        this.isDeleteScholarshipOpen = false;
    }
    @action
    deleteScholarship() {
        let existingScholarships1 = getAllValuesForTasField('SCHOLARSHIPS_RECEIVED', this.args.model.application.fields);
        let updatedScholarships1 = existingScholarships1.filter((scholarship1: FieldValue)=>(scholarship1 as Record<string, unknown>)?.scholarshipName !== this.scholarshipToDelete?.scholarshipName) as ReceivedScholarship[];
        this.applicationFields['SCHOLARSHIPS_RECEIVED'] = {
            values: updatedScholarships1.filter((scholarship1)=>scholarship1 !== null)
        };
        this.args.model.application.fields = this.applicationFields;
        this.isDeleteScholarshipOpen = false;
    }
    @action
    openEditScholarship(scholarship1: ReceivedScholarship) {
        this.scholarshipToEdit = scholarship1;
        this.isEditScholarshipOpen = true;
    }
    @action
    closeEditScholarship() {
        this.scholarshipToEdit = null;
        this.isEditScholarshipOpen = false;
    }
    @action
    updateName(value1: string) {
        this.scholarshipToEdit!.scholarshipName = value1;
    }
    @action
    updateAmount(value1: string) {
        this.scholarshipToEdit!.scholarshipAmount = currencyStringToNumber(value1);
    }
    @action
    async checkForMissingFields() {
        if (this.scholarshipToEdit?.scholarshipName === '' || this.scholarshipToEdit?.scholarshipAmount === 0) {
            this.scholarshipEditError = 'A required field is missing';
        } else {
            this.scholarshipEditError = '';
        }
    }
    @action
    async updateScholarship() {
        await this.checkForMissingFields();
        if (this.scholarshipEditError !== '') {
            return;
        }
        const updatedScholarships1 = this.scholarships.map((scholarship1)=>scholarship1.id === this.scholarshipToEdit?.id ? {
                scholarshipName: this.scholarshipToEdit.scholarshipName,
                scholarshipAmount: this.scholarshipToEdit.scholarshipAmount
            } : scholarship1);
        this.args.model.application.fields['SCHOLARSHIPS_RECEIVED'] = {
            values: updatedScholarships1
        };
        this.scholarshipToEdit = null;
        this.closeEditScholarship();
    }
    @action
    getValue(values1: string[] | boolean[]): string {
        return values1[0]?.toString() || '';
    }
    inputClasses = {
        base: 'w-full mb-6'
    };
    static{
        template(`
    <TasPageHeader>
      <:title as |title|>
        {{! TAS.TODO: temporary routing until tas dashboard is complete }}
        <title.BackLink @label="Back" @route="authenticated.tuition-assistance.dashboard">
          {{t "sidebar.tuition_assistance"}}
        </title.BackLink>
      </:title>
      <:actions as |actions|>
        <actions.Button data-test-save-application {{on "click" this.onSave}}>
          {{t "save"}}
        </actions.Button>
        <actions.IconButton
          data-test-delete-application
          {{on "click" this.onTrash}}
          @type="trash"
        />
      </:actions>
    </TasPageHeader>

    <HStack>
      <VStack class="h-full md:!w-2/3">
        <Section>
          <:header>
            <div class="flex justify-between">
              <p>{{t "tas.application.type_two.new.application"}}</p>
              <p class="font-normal text-base">
                <span class="text-error-400 text-xl">*</span>
                {{t "tas.application.type_two.new.required_items"}}
              </p>
            </div>
          </:header>
          <:body>
            <VStack>
              <div class="flex justify-between items-center w-full">
                {{#if this.startDateConfig.visible}}
                  <div class="w-5/12">
                    <label for="start" class="text-gray-600 font-semibold">
                      {{t "tas.application.type_two.new.start_date"}}
                      {{#if this.startDateConfig.required}}
                        <span class="text-error-400">*</span>
                      {{/if}}
                    </label>
                    <Input
                      @value={{this.startDate}}
                      @onInput={{this.saveStartDate}}
                      @type="date"
                      @classes={{this.inputClasses}}
                      id="start"
                    />
                  </div>
                {{/if}}
                {{#if this.endDateConfig.visible}}
                  <div class="w-5/12">
                    <label for="end" class="text-gray-600 font-semibold">
                      {{t "tas.application.type_two.new.end_date"}}
                      {{#if this.endDateConfig.required}}
                        <span class="text-error-400">*</span>
                      {{/if}}
                    </label>
                    <Input
                      @value={{this.endDate}}
                      @onInput={{this.saveEndDate}}
                      @type="date"
                      @classes={{this.inputClasses}}
                      id="end"
                    />
                  </div>
                {{/if}}
              </div>
              {{#unless this.eligibility}}
                <TioAlert @type="error" @allowDismiss={{false}} class="mb-4">
                  <:header>
                    <p class="font-bold" data-test-eligibility-error>
                      {{t "start_date_eligibility.errorHeader" startEndSubmission=this.lockStatus}}
                    </p>
                  </:header>
                  <:body>
                    <p class="text-sm">
                      {{t "start_date_eligibility.errorText"}}
                    </p>
                  </:body>
                </TioAlert>
              {{/unless}}
              <Divider class="my-2" />
              <div class="flex items-center w-full py-4">
                <div class="w-1/2">
                  <h2 class="text-gray-600 font-semibold">{{t "tas.application.type_two.new.name"}}
                    <span class="text-error-400">*</span>
                  </h2>
                </div>
                <div class="w-1/2">
                  <label for="application-name" class="sr-only">
                    {{t "tas.application.type_two.new.name"}}!!
                  </label>
                  <Input
                    size="lg"
                    data-test-application-name
                    id="application-name"
                    @value={{getSingleValueForTasField
                      "APPLICATION_NAME"
                      @model.application.fields
                    }}
                    class="!bg-transparent"
                    @onChange={{this.updateAppName}}
                  />
                </div>
              </div>

              <TuitionAssistanceFormsCustomFieldsForProvince
                @province="APPLICATION"
                @customFields={{this.applicationCustomFields}}
                @didUpdateFields={{this.didUpdateCustomFields}}
                @formValidationErrors={{this.customFieldValidationErrors}}
                @customFieldModel="applicationCustomFields"
              />
            </VStack>
          </:body>
        </Section>

        <Section>
          <:header>
            <div class="flex justify-between items-center">
              <p>{{this.expenseTypeField}}</p>
              <Button
                class="text-ocean-600 text-base font-normal border-ocean-600"
                @appearance="outlined"
                data-test-add-education-drawer
                {{on "click" (fn this.openEducationDrawer null)}}
              >
                <Plus class="h-6 w-6 mr-2" />
                {{or this.expenseTypeField (t "tas.application.type_two.new.education")}}
              </Button>
            </div>
          </:header>
          <:body>
            <VStack>
              <p>{{t "tas.application.type_two.new.expense_type_description"}}</p>
              <ActionableList @striped={{false}} as |l|>
                {{#each @model.application.activeCourses as |course|}}
                  <l.Row>
                    <l.Term class="md:w-1/3">{{course.courseName}}</l.Term>
                    <l.Details>
                      <l.Item class="justify-self-center md:justify-self-start">
                        {{safeFormatNumber
                          (divide course.courseTotal 100)
                          style="currency"
                          currency="USD"
                        }}
                      </l.Item>
                      <l.Item
                        class="justify-self-center md:justify-self-start font-semibold"
                      >{{course.courseGrade}}</l.Item>
                    </l.Details>
                    <l.Action>
                      <button
                        type="button"
                        data-test-delete-course
                        {{on "click" (fn this.toggleDeleteCourse course)}}
                      ><XCircle class="h-6 w-6" /></button>
                      <button
                        type="button"
                        data-test-edit-education-drawer
                        {{on "click" (fn this.openEducationDrawer course)}}
                      ><PencilSquare class="h-6 w-6" /></button>
                    </l.Action>
                  </l.Row>
                {{/each}}
              </ActionableList>
            </VStack>
          </:body>
        </Section>
        {{! Add Scholarships }}
        <Section>
          <:header>
            <div class="flex justify-between items-center">
              <p>
                {{t "tas.new.scholarships.default"}}
                <span class="text-normal">{{t "tas.new.scholarships.optional"}}</span>
              </p>
              <Button
                @appearance="outlined"
                class="text-ocean-600 hover:text-ocean-700 border-ocean-600 text-base font-normal"
                data-test-add-scholarship-drawer
                {{on "click" this.toggleScholarshipDrawer}}
              >
                <Plus class="h-6 w-6 mr-2" />
                {{t "tas.new.scholarships.default"}}
              </Button>
            </div>
          </:header>
          <:body>
            <VStack>
              <p>{{t "tas.new.scholarships.please_enter_any_scholarships"}}</p>
              <ActionableList @striped={{false}} as |l|>
                {{#each this.scholarships as |scholarship|}}
                  <l.Row>
                    <l.Term>{{scholarship.scholarshipName}}</l.Term>
                    <l.Details>
                      <l.Item class="justify-self-center">
                        {{safeFormatNumber
                          (divide scholarship.scholarshipAmount 100)
                          style="currency"
                          currency="USD"
                        }}
                      </l.Item>
                    </l.Details>
                    <l.Action>
                      <button type="button" data-test-delete-scholarship>
                        <XCircle
                          class="h-6 w-6 text-gray-700"
                          {{on "click" (fn this.openDeleteScholarship scholarship)}}
                        />
                      </button>
                      <button type="button" data-test-edit-scholarship>
                        <PencilSquare
                          class="h-6 w-6 text-gray-700"
                          {{on "click" (fn this.openEditScholarship scholarship)}}
                        />
                      </button>
                    </l.Action>
                  </l.Row>
                {{/each}}
              </ActionableList>
            </VStack>
          </:body>
        </Section>

        {{! Upload Attachments}}
        <Section>
          <:header>{{t "attachments"}}</:header>
          <:body>
            <VStack>
              <p>
                {{t "tas.application.type_two.new.attachment_description"}}
                {{#if this.requireAttachmentUploads}}
                  <span class="text-error-400 text-xl">*</span>
                {{/if}}
              </p>
              <UploadAttachments
                @programTemplate={{this.programTemplate}}
                @classification={{this.attachmentClassificationString}}
                @attachable={{@model.application}}
                @attachableType="tasApplication"
                @useDropzone={{true}}
                {{!-- @disabled={{this.disableAttachmentUpload}} --}}
              />
            </VStack>
          </:body>
        </Section>
      </VStack>

      <Section class="w-full md:!w-1/3">
        <:header>{{t "tas.benefits_summary.title"}}</:header>
        <:body>
          <LimitsSummary
            @application={{@model.application}}
            @employee={{@model.programInstance.employee}}
            @labelClass="col-span-5"
            @valueClass="col-span-2 text-violet-800"
            class="my-4"
            as |Summary|
          >
            <div class="w-full grid grid-cols-7 gap-2 mt-3">
              {{#unless Summary.isUnlimitedMaximumAnnualBenefit}}
                <Summary.MaxAnnualBenefitLineItem />
              {{/unless}}
              {{#if Summary.hasLimitsErrorForEmployee}}
                <Summary.RemainingBenefitLineItem />
              {{/if}}
              <Summary.ApplicationAmountRequestedWithoutScholarshipsLineItem
                @valueClass="text-tio-gray-800 col-span-2"
              />
              {{#if this.programTemplate.scholarshipsAreVisible}}
                <Summary.ScholarshipAmountLineItem
                  @valueClass="text-tio-gray-800 !font-normal col-span-2"
                />
              {{/if}}
              <Summary.LimitsAlertForEmployeeIfApplicable class="text-sm col-span-full" />
              <ReimbursementCalculationIfApplicable @application={{@model.application}} />
              <Divider class="my-2 col-span-7" />
              <Summary.ApplicationAmountRequestedLineItem />
            </div>

            {{! Required Items Section }}
            <section class="my-5">
              <header
                class="border-y bg-tio-gray-25 flex items-center gap-2 py-2 px-5 text-sm font-medium tracking-wide -mx-4"
              >
                <ClipboardDocumentList class="h-8 w-8" />
                <span>{{t "tas.benefits_summary.required_items"}}</span>
              </header>

              <div>
                <div class="flex flex-row justify-between items-center">
                  <p>{{t "tas.benefits_summary.attachments"}}
                    <span class="text-error-400 ml-1">
                      {{#if this.requireAttachmentUploads}}*{{/if}}
                    </span>
                  </p>
                  <div>
                    <UploadAttachments
                      @programTemplate={{this.programTemplate}}
                      @classification={{this.attachmentClassificationString}}
                      @attachable={{@model.application}}
                      @attachableType="tasApplication"
                      @useDropzone={{false}}
                      @iconOnlyUpload={{true}}
                      @hideAttachments={{true}}
                      @hideInstructions={{true}}
                      {{!-- @disabled={{this.disableAttachmentUpload}} --}}
                    />
                  </div>
                </div>
                <div class="flex items-center gap-3 -mt-1">
                  {{#if this.hasRequiredAttachments}}
                    <CheckCircleSolid class="h-6 w-6 text-violet-500" />
                  {{else}}
                    <CheckCircle class="h-6 w-6 text-tio-gray-200" />
                  {{/if}}
                  <p>{{this.attachmentsListDescription}}</p>
                </div>
              </div>

              {{#if this.conditionsForSubmit.length}}
                <AcceptConditions
                  @conditions={{this.conditionsForSubmit}}
                  @onChange={{this.didUpdateAcceptedConditions}}
                />
              {{/if}}
            </section>

            <div class="py-4">
              {{#if this.canSubmitApplication}}
                <Button
                  class="flex justify-center w-full py-3 px-6 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg hover:bg-ocean-400"
                  data-test-submit-application
                  {{on "click" this.submitApplication}}
                >
                  {{t "tas.benefits_summary.submit_application"}}
                </Button>
              {{else}}
                <MissingRequirements />
              {{/if}}
              <p class="text-sm font-light text-center">
                {{t "tas.benefits_summary.helper_text"}}
              </p>
              {{#if this.submitError}}
                <TioErrorMessages
                  @showErrors={{true}}
                  @error={{this.submitError}}
                  class="text-sm"
                  @icon="warning"
                />
              {{/if}}
            </div>
          </LimitsSummary>
        </:body>
      </Section>
    </HStack>

    {{#if this.isEducationOpen}}
      <AddEducationDrawer
        @isOpen={{this.isEducationOpen}}
        @onClose={{this.closeEducationDrawer}}
        @application={{@model.application}}
        @course={{this.course}}
        @header={{this.expenseTypeField}}
      />
    {{/if}}
    <AddScholarshipsDrawer
      @isOpen={{this.isScholarshipOpen}}
      @onClose={{this.toggleScholarshipDrawer}}
      @application={{@model.application}}
    />

    {{! Delete Application Modal }}
    <Modal
      @isOpen={{this.isDeleteApplicationOpen}}
      @onClose={{this.toggleDeleteApplication}}
      data-test-delete-application-modal
      as |m|
    >
      <m.Header>{{t "tas.application.type_two.new.delete_application"}}</m.Header>
      <m.Body>
        <p>{{t "tas.application.type_two.new.confirm_application_delete"}}</p>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.deleteApplication.isRunning}}
          @onCancel={{this.toggleDeleteApplication}}
          @onSubmit={{this.deleteApplication.perform}}
        />
      </m.Footer>
    </Modal>

    <Modal
      @isOpen={{this.isDeleteCourseOpen}}
      @onClose={{fn this.toggleDeleteCourse null}}
      data-test-delete-course-modal
      as |m|
    >
      <m.Header>{{t "tas.application.type_two.new.delete_course"}}</m.Header>
      <m.Body>
        <p>{{t "tas.application.type_two.new.confirm_delete_expense"}}:</p>
        <p class="font-semibold px-2">{{this.course.courseName}}</p>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.deleteCourse.isRunning}}
          @onCancel={{fn this.toggleDeleteCourse null}}
          @onSubmit={{this.deleteCourse.perform}}
        />
      </m.Footer>
    </Modal>
    {{! Delete Scholarship Modal }}
    <Modal
      @isOpen={{this.isDeleteScholarshipOpen}}
      @onClose={{this.closeDeleteScholarship}}
      data-test-delete-scholarship-modal
      as |m|
    >
      <m.Header>{{t "tas.application.type_two.new.delete_scholarship"}}</m.Header>
      {{#let this.scholarshipToDelete as |scholarship|}}
        <m.Body>
          <p>{{t "tas.application.type_two.new.confirm_scholarship_delete"}}</p>
          <div class="p-8 flex w-2/3 justify-between">
            <p class="font-medium">{{scholarship.scholarshipName}}</p>
            <p class="font-medium">{{safeFormatNumber
                (divide scholarship.scholarshipAmount 100)
                style="currency"
                currency="USD"
              }}</p>
          </div>
        </m.Body>
      {{/let}}
      <m.Footer>
        <Button @appearance="minimal" class="mr-4" {{on "click" this.closeDeleteScholarship}}>
          {{t "cancel"}}
        </Button>
        <Button @intent="danger" {{on "click" this.deleteScholarship}}>
          {{t "delete"}}
        </Button>
      </m.Footer>
    </Modal>
    {{! Edit Scholarship Modal }}
    <Modal
      @isOpen={{this.isEditScholarshipOpen}}
      @onClose={{this.closeEditScholarship}}
      data-test-edit-scholarship-modal
      as |m|
    >
      <m.Header>{{t "tas.application.type_two.new.edit_scholarship"}}</m.Header>
      <m.Body>
        <div class="flex flex-col w-full">
          <label for="scholarship_name4" class="font-semibold text-gray-600">
            {{t "tas.add_scholarships_drawer.scholarship_name"}}
            <span class="text-error-400">*</span>
          </label>
          <Input
            @value={{this.scholarshipToEdit.scholarshipName}}
            @classes={{this.inputClasses}}
            @onChange={{this.updateName}}
            @isRequired={{true}}
            data-test-edit-scholarship-name
          />
          <label for="scholarship_amount4" class="font-semibold text-gray-600">
            {{t "tas.add_scholarships_drawer.scholarship_amount"}}
            <span class="text-error-400">*</span>
          </label>
          <Input
            @value="{{safeFormatNumber
              (divide this.scholarshipToEdit.scholarshipAmount 100)
              style="currency"
              currency="USD"
            }}"
            @classes={{this.inputClasses}}
            @onChange={{this.updateAmount}}
            @isRequired={{true}}
            data-test-edit-scholarship-amount
            {{inputmask alias="currency" prefix="\$" unmaskAsNumber=true digits="2"}}
          />
          <p class="text-danger">{{this.scholarshipEditError}}</p>
        </div>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onCancel={{this.closeEditScholarship}}
          @onSubmit={{this.updateScholarship}}
        />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
