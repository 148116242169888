import Service from '@ember/service';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import { v4 as uuid } from 'uuid';
import type SessionContextService from './session-context';
import type FinancialInstitutionModel from 'tio-common/models/financial-institution';
import type ObservableDocumentModel from 'tio-common/models/observable-document';

export type LogLevel = 'DEBUG' | 'INFO' | 'WARNING' | 'ERROR';
export type ProviderComponent = 'LoginSpinwheel' | 'LoginFinicityConnectLite';

type ObservabilityEventType =
  | 'INITIALIZE'
  | 'UPLOAD_START'
  | 'UPLOAD_FAILURE'
  | 'UPLOAD_SUCCESS'
  | 'PROCESSING_SUCCESS'
  | 'PROCESSING_FAILURE'
  | 'DISPUTE_SUBMITTED';
interface ObservabilityEventOptions {
  eventName: ObservabilityEventType;
  component: string;
  document?: ObservableDocumentModel;
}

export default class RemoteEventLoggerService extends Service {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  async log(
    provider: 'spinwheel' | 'finicity',
    context: unknown,
    logLevel: LogLevel = 'INFO',
    component: ProviderComponent,
    institution?: FinancialInstitutionModel,
    callback?: string
  ) {
    const remoteEvent = this.store.createRecord('remote-event', {
      eventType: 'REMOTE_LOGGER',
      payload: {
        logLevel: logLevel,
        data: {
          event: {
            id: uuid(),
            institution: {
              id: institution?.id || 'unknown',
              name: institution?.name || 'unknown',
            },
            company: {
              id: this.sessionContext.currentRole?.company?.id || 'unknown',
              name: this.sessionContext.currentRole?.company.name || 'unknown',
            },
            [provider]: {
              context,
              callback,
            },
          },
          component,
        },
      },
    });
    remoteEvent.user = this.sessionContext.user;
    return remoteEvent.save();
  }

  async logObservabilityEvent(options: ObservabilityEventOptions) {
    const remoteEvent = this.store.createRecord('remote-event', {
      eventType: 'REMOTE_LOGGER',
      payload: {
        logLevel: 'INFO',
        data: {
          component: options.component,
          event: {
            id: uuid(),
            company: {
              id: this.sessionContext.currentRole?.company?.id || 'unknown',
              name: this.sessionContext.currentRole?.company.name || 'unknown',
            },
            document: { id: options.document?.id || 'unknown' },
            asset: { id: options.document?.observableAsset?.id || 'unknown' },
            servicer: {
              id: options.document?.financialInstitution?.id || 'unknown',
              name: options.document?.financialInstitution?.name || 'unknown',
            },
            loanCount: (<unknown[]>options.document?.extractedContent?.loans).length || 0,
            documentType: options.document?.provider || 'unknown',
          },
        },
      },
    });
    remoteEvent.user = this.sessionContext.user;
    return remoteEvent.save();
  }
}
